import { ContentContainerAppClient, PageTitle, Text, useTranslate } from '@orus.eu/pharaoh'
import { memo } from 'react'
import { trpcReact } from '../../client'
import { SmallScreenMargin } from '../templates/small-screen-margin'
import { getUserContractsByActivitiesAndAddresses } from './contracts-by-activity-and-address'
import { ContractsByActivitiesAndAddresses } from './contracts-page'

const GuaranteesPage = memo(function GuaranteesPage(): JSX.Element {
  const translate = useTranslate()
  const [contracts] = trpcReact.contracts.listMyContracts.useSuspenseQuery()

  if (contracts.length === 0) {
    return (
      <SmallScreenMargin>
        <Text variant="body1">{translate('no_active_contract')}</Text>
      </SmallScreenMargin>
    )
  }

  const userContractsByActivitiesAndAddresses = getUserContractsByActivitiesAndAddresses(contracts)

  return (
    <>
      <PageTitle title={translate('my_guarantees')} />
      <ContentContainerAppClient>
        <ContractsByActivitiesAndAddresses
          userContractsByActivitiesAndAddresses={userContractsByActivitiesAndAddresses}
          displayContractNumber={false}
          navigateToGuaranteePage
        />
      </ContentContainerAppClient>
    </>
  )
})

export default GuaranteesPage
